import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import T_ABOUT_QUESTIONS from "../../components/t_about_questions"

const AboutLotionPage = () => (
  <Layout sectionClasses={"t-about-detail"} dataNamespace={"about_lotion"}>
    <SEO title="About Lotion" />
    <div className="t-about-lotion">
      <div className="t-about-detail__kv">
        <div className="t-about-detail__kv__ttl">
          <h1>LOTION</h1>
        </div>
        <picture className="t-about-detail__kv__img">
          <source media="(max-width:959px)" srcSet={"/images/about/lotion-kv-sp.jpg"} />
          <img  src={"/images/about/lotion-kv-pc.jpg"} alt="" />
        </picture>
      </div>

      <div className="t-about-detail__product">
        <div className="t-about-detail__product__inner">
          <div className="t-about-detail__product__ttl">
            <p>HOTARU<br />PERSONALIZED<br />LOTION</p>
          </div>
          <div className="t-about-detail__product__img">
            <img src={"/images/about/lotion-bottle.png"} alt="" />
          </div>
          <div className="t-about-detail__product__txt">
            <p className="t-about-detail__product__txt__ttl">大切なのは、<br />うるおいを保ち続ける力。</p>
            <p className="t-about-detail__product__txt__desc">肌にうるおいを与える化粧水。<br />しかし、肌がうるおいを維持する力が衰えてしまえば、そのうるおいも一時的なものです。<br />消えない乾燥の不安を和らげるために大切なのは、肌が水分、油分を補い保つことをサポートすることです。</p>
          </div>
        </div>
      </div>

      <div className="t-about-detail__pt1">
        <div className="t-about__pt__ttl"><p>POINT</p></div>
        <div className="t-about-detail__pt1__txt">
          <p className="t-about-detail__pt1__txt__ttl">わたし好みのうるおい感を目指したローション。</p>
          <p className="t-about-detail__pt1__txt__desc">うるおいを与える、肌の水分を補い保つ、肌の乾燥を防ぐ。HOTARU PERSONALIZED LOTIONは、あらゆるアプローチで一人ひとりが心地よい肌のうるおいを実現することを目指したローション（化粧水）です。</p>
        </div>
        <div className="t-about-detail__pt1__img">
          <img src={"/images/about/lotion-point1.jpg"} alt="" />
        </div>
        <ul>
          <li><span>肌の水分、油分を補い保つ。</span></li>
          <li><span>肌にうるおいを与える。</span></li>
          <li><span>肌を保護し、乾燥を防ぐ。</span></li>
        </ul>
      </div>

      <div className="t-about-detail__pt2">
        <img className="t-about__bgImg1" src={"/images/about/color1.png"} alt="" />
        <img className="t-about__bgImg2" src={"/images/about/color2.png"} alt="" />
        <div className="t-about__pt__ttl"><p>POINT</p></div>
        <div className="t-about-detail__inner">
          <div className="t-about-detail__inner__img">
            <img src={"/images/about/lotion-water.jpg"} alt="" />
          </div>
          <div className="t-about-detail__inner__txt">
            <p className="t-about-detail__inner__txt__ttl">あなたの肌に力を与える自然の力</p>
            <p className="t-about-detail__inner__txt__desc">日本水は、埼玉県寄居町の風布川源流、釜伏山北面から湧き出す天然水。日本名水百選にも選ばれた「日本水」から発見された「日本酵母」から抽出できる、加水分解酵母エキスには、遊離アミノ酸が豊富。国内でも有数の天然水から得られた自然の恵みが、みずみずしく輝く肌へと導きます。</p>
          </div>
        </div>
      </div>

      <div className="t-about-detail__pt3">
        <div className="t-about__pt__ttl"><p>POINT</p></div>
        <div className="t-about-detail__inner">
          <div className="t-about-detail__inner__img">
            <img src={"/images/about/lotion-personalize.jpg"} alt="" />
          </div>
          <div className="t-about-detail__inner__txt">
            <p className="t-about-detail__inner__txt__ttl">さらにあなたの肌に合わせて<br />パーソナライズ</p>
            <p className="t-about-detail__inner__txt__desc">肌にうるおいを与えるベース処方設計に加え、あなたの肌の水分・油分状態を計測・解析し、ぴったりなローションの処方を提案。あなたの理想のうるおい感へ。</p>
          </div>
        </div>
      </div>

      <Link className="t-about-detail__label" to="/about/moisturizer/">
        <div className="t-about-detail__label__inner">
          <div className="t-about-detail__label__ttl">MOISTURLIZER</div>
          <div className="t-about-detail__label__more">VIEW MORE</div>
        </div>
      </Link>
      <T_ABOUT_QUESTIONS />
      <Link class="p-pageBackBtn" to="/">
        <div class="c-grid">
          <div class="c-grid__row">
            <div class="c-grid__col">
              <span class="p-pageBackBtn__inner">BACK TO TOP</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  </Layout>
)

export default AboutLotionPage
